// Runs when the DOM has loaded
$(function(){
    var $window = $(window);
    var $body = $('body');
    var $nav = $('nav.navbar.navbar-main');

    ///////////////////////
    // Bootstrap Tooltip //
    ///////////////////////

    // app script
    // $('[data-toggle="tooltip"]').tooltip();

    //////////////////
    // Box Collapse //
    //////////////////

    $('.box-collapse .collapse').on('show.bs.collapse', function () {
        var $parent = $(this).parent(); // .box-collapse
        // reset
        resetBoxCollapse($parent);
        // add class when box is collapsing down
        $parent.addClass('box-collapsed');
    });

    $('.box-collapse .collapse').on('hide.bs.collapse', function () {
        var $parent = $(this).parent(); // .box-collapse
        // reset
        resetBoxCollapse($parent);
    });

    // Group
    $('.box-collapse-group').each(function(){
        var $boxCollapseGroup = $(this);

        // Collapse only with .box-collapsed-on-load
        $boxCollapseGroup.find('.box-collapsed-on-load').find('.collapse').collapse('show');
        $boxCollapseGroup.find('.box-collapse').not('.box-collapsed-on-load').find('.collapse').collapse('hide');

        // Only one collapsed at a time
        $boxCollapseGroup.find('.box-collapse').each(function(){
            var $el = $(this);
            var collapseId = $el.find('a').first().attr('href');
            var $collapse = $(collapseId);

            $collapse.on('show.bs.collapse', function () {
                var collapses = getCollapsesByGroup($boxCollapseGroup);

                // close all other box-collapse in the group
                for (i=0; i < collapses.length; i++) {
                    var $otherCollapse = collapses[i];

                    if ($otherCollapse != $collapse) {
                        // hide it
                        $otherCollapse.collapse('hide');
                    }
                }

            });
        });
    });

    //////////////////
    // Owl Carousel //
    //////////////////

    // Convert box-carousel to owlCarousel
    $('.box-carousel').addClass('owl-carousel owl-theme')
        .each(function(){
            var $el = $(this);
            var id = $el.attr('id');

            // get options
            var items = $el.data('items') || 1;
            var margin = $el.data('margin') || 0;
            var singleItem = (items == 1) ? true : false;
            var autoPlay = $el.data('autoplay') || false;
            var center = $el.data('center') || false;

            $('#'+id).owlCarousel({
                // autoPlay: 5000, //Set AutoPlay to 3 seconds
                // navigation : true, // Show next and prev buttons
                // slideSpeed : 300,
                // paginationSpeed : 400,
                center: center,
                singleItem: singleItem,
                items: items,
                stopOnHover: true,
                scrollPerPage: true,
                autoPlay: autoPlay
                // // Owl Carousel 2
                // loop: true,
                // margin: 10,
            });
    });

    ///////////////////
    // Button to top //
    ///////////////////

    $('.btn-to-top').on('click', function(e){
        e.preventDefault();

        $body.animate({ scrollTop: 0 }, 'slow');
    });

    ///////////////////////
    // Adjust hash links //
    ///////////////////////

    $('a.follow-hash[href*="#"]').on('click', function(e){
        var $el = $(e.currentTarget);
        var $target = $($el.attr('href'));
        scrollToTarget($target);

        return false;
    });

    // on page load, when url has hash
    if(window.location.hash) {
        var $target = $(window.location.hash);
        scrollToTarget($target);
    }

    ////////////////////
    // Animate Number //
    ////////////////////

    $('.animate-number').html('0');
    $( '.animate-number' ).each(function(){
        // associate by id instead
        var id = $(this).attr('id');

        // if no id generate one
        if (! id) {
            var d = new Date();
            var dateNow = d.getTime();
            id = 'animateNumber-' + dateNow;
            $(this).attr('id', id);
        }

        var $el = $('#'+id);

        var number = $el.data('number');
        var triggerScrollTop = $el.offset().top;

        $window.scroll(function(){
            if (isTargetVisible($el, .1) && ! $el.hasClass('number-animated')) {
                $el.animateNumbers( number ).addClass('number-animated');
            }
        });
    });

    ///////////////////
    // User Settings //
    ///////////////////
    var $citiesWrapper = $('.cities-wrapper');

    // Load cities drop down if province is selected on page load
    loadCities();
    // hide cities wrapper on load
    $citiesWrapper.hide();

    // change the cities if the province changes
    $('#provinceSelect').change(function() {
        loadCities();
    });

    function loadCities() {
        var $selectedProvinces = $('#provinceSelect :checked');
        var $cities = $('#citySelect');
        $cities.html('');

        $selectedProvinces.each(function() {
            for (count in cities) {
                if (cities[count]['province_id'] == $(this).val()) {
                    $cities.append(
                        '<div class="checkbox checkbox-toggle">' +
                            '<input type="checkbox" id="checkboxToggleCity-' + cities[count]['city_id'] + '" name="cities[]" value="' + cities[count]['city_id'] + '" ' + ((in_array(cities[count]['city_id'], selectedCities)) ? 'checked="checked"' : '') + ' />' +
                            '<label for="checkboxToggleCity-' + cities[count]['city_id'] +'">' + cities[count]['city_name'] + '</label>' +
                        '</div>\n'
                    );
                }
            }
        });

        // if no provinces selected, hide wrapper
        if ($selectedProvinces.length) {
            $citiesWrapper.slideDown();
        } else {
            $citiesWrapper.slideUp();
        }
    }

    ///////////////
    // Lazy Load //
    ///////////////

    var bgAttr = 'data-bg';
    var srcAttr = 'data-src';
    var $lazyBg = $('[data-bg]');

    $.extend($.lazyLoadXT, {
        srcAttr: srcAttr,
        bgAttr: bgAttr
    });

    // temp fix for lazy loading bg issue
    $lazyBg.on('lazyshow', function (e) {
        var $el = $(e.target);
        var imgSrc = $el.attr(bgAttr);

        // check if image has loaded
        var $img = $('<img/>');
        $img.attr('src', imgSrc);
        $img.on('load', function(e){
            $el.removeClass('lazy-hidden').addClass('lazy-loaded');
        });
    });

    /////////////////
    // Page Ribbon //
    /////////////////

    // adjust ribbon height on load
    adjustRibbonHeight();

    ///////////////////
    // Window Events //
    ///////////////////

    $window.scroll(function(){
        stickyNav();
    });

    $window.resize(function() {
        adjustRibbonHeight();
    });

    /////////////////////////
    // Click Event Tracker //
    /////////////////////////

    $('.event-tracker').on('click', function (e) {
        var $el = $(this);
        var category = $el.data('category');
        var action = $el.data('action');
        var label = $el.data('label');

        ga('send', 'event', {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: 1,
            transport: 'beacon'
        });
    });

});

// Runs when the page has rendered (including images)
$( window ).load(function() {
    var $main = $('main');
    var $window = $(window);

    /////////////
    // Masonry //
    /////////////
    // Note: Masonry not working in IE8 or lower

    // Browser is not IE 8 or lower
    if (! isIe()) {
        var masonry = $('.masonry').masonry({
            // options
            itemSelector: '.masonry-item',
            columnWidth: '.masonry-item'
        }).on('item-resized', function(e){
            $(this).masonry();
        }).on('item-lazy-load', function(e){
            $(this).masonry();
        });

        //////////////////////////////////
        // Lazy Load Images and Masonry //
        //////////////////////////////////

        $('.lazy-bg, .lazy-img').on('lazyload', function(){
            masonry.trigger('item-lazy-load');
        });

        //////////////////////////////
        // Box Carousel for Masonry //
        //////////////////////////////

        $('.box-collapse .collapse').on('shown.bs.collapse', function () {
            // trigger masonry, item-resized
            masonry.trigger('item-resized');
        });

        $('.box-collapse .collapse').on('hidden.bs.collapse', function () {
            // trigger masonry, item-resized
            masonry.trigger('item-resized');
        });
    }

    ///////////////
    // Box Video //
    ///////////////

    $('.box-video').each(function(){
        var $el = $(this);
        var $parent = $el.parent('div');
        var $youTubeVideoId = $el.data('youtube');
        var $boxBody = $el.find('.box-body');
        var ratio = 9/16; // 16:9 aspect ratio
        var origHeight = $el.height();

        $el.on('click', function(){
            $el.toggleClass('playing');
            $parent.addClass('transition-width');

            var width = $main.width(); // video plays at full width
            var height = getHeightByRatio(width, ratio);
            var youtubeId = $el.data('youtube'); // data-youtube

            if (youtubeId) {
                // youtube options
                var rel = $el.data('rel'); // data-rel
                var autoplay = $el.data('autoplay'); // data-autoplay
                var hl = $el.data('hl'); // data-hl
                var startAt = $el.data('startat');

                // default true/1
                rel = (rel == 'false' || rel == '0') ? 0 : 1;
                // default true/1
                autoplay = (autoplay == 'false' || autoplay == '0') ? 0 : 1;
                //
                hl = hl ? hl : 'en-ca';

                // build url parameters
                urlParameters = '/?autoplay='+autoplay+'&rel='+rel+'&hl='+hl+'&start='+startAt;

                if ($el.hasClass('playing')) {
                    if ($parent) {
                        $parent.css('width', '100%');
                    }

                    $el.css('height', '');

                    // ratio of 630/1120
                    $boxBody.html(
                        '<div class="video-container">'+
                            '<iframe type="text/html" ' +
                                'style="width:100%; height:'+height+'px;" ' +
                                'src="https://www.youtube.com/embed/'+youtubeId+urlParameters+'" ' +
                                'frameborder="0" ' +
                                'allowfullscreen>' +
                            '</iframe>'+
                        '</div>'
                    ).hide().css('display', '').slideDown(400);
                } else {
                    if ($parent) {
                        $parent.css('width', '');
                    }

                    // restore original/initial height
                    $el.css('height', origHeight);

                    $boxBody.find('.video-container').slideUp(function(){
                        $boxBody.html('').css('display', 'table-cell');
                    });
                }
            }

            return false;
        });

        $window.on('resize', function(){
            videoResize($el, ratio);
        });
    });

    $('.box-embed-video').each(function(){
        var $el = $(this);
        var ratio = 9/16; // 16:9 aspect ratio

        $window.on('resize', function(){
            videoResize($el, ratio);
        });

        videoResize($el, ratio);
    });
});

///////////////////
// Page Specific //
///////////////////

$('body.page-home').ready(function(){

});

$('body.page-job').ready(function(){
    $applyBtn = $('.btn-apply');

    $applyBtn.on('click', function(e){
        var $el = $(this);
        var referer = $el.data('referer');
        var jobId = $el.data('id');
        var companyId = $el.data('company');

        // add referer event
        var category = 'job-referer';
        var action = companyId + '-' + jobId;
        var label = referer ? referer : 'none'; // referer path

        ga('send', 'event', category, action, label, 1);

        // add apply event
        var category = 'job-apply';
        var action = companyId + '-' + jobId;
        var label = $el.data('label'); // button position

        ga('send', 'event', category, action, label, 1);
    });

    $popup4UploadBtn = $('.popup4-upload-btn');

    $popup4UploadBtn.on('click', function(e){
        var $el = $(this);
        var jobId = $el.data('id');
        var companyId = $el.data('company');

        // add event
        var category = 'job-detail-page';
        var action = 'popup4-upload-resume-video-btn'
        var label = companyId + '-' + jobId;

        ga('send', 'event', category, action, label, 1);
    });

    $popup4ApplyNowBtn = $('.popup4-apply-now-btn');

    $popup4ApplyNowBtn.on('click', function(e){
        var $el = $(this);
        var jobId = $el.data('id');
        var companyId = $el.data('company');

        // add event
        var category = 'job-detail-page';
        var action = 'popup4-apply-now-btn'
        var label = companyId + '-' + jobId;

        ga('send', 'event', category, action, label, 1);
    });
});

$('body.page-register_with_only_email').ready(function(){
    $IncubatorRegisterBtn = $('.btn-incubator-register');

    $IncubatorRegisterBtn.on('click', function(e){
        var $el = $(this);
        
        // add event
        var category = 'register-functionality';
        var action = 'register-btn-step-one'
        var label = 'incubator-homepage-register-widget';

        ga('send', 'event', category, action, label, 1);
    });
});

///////////////
// Functions //
///////////////

/**
 * Adjust ribbon height to target element, .ribbon-target
 *
 * Options:
 *   data-ribbon-offset (integer)
 *     - adjust ribbon upto the top of the target
 *       offset by the given value
 *
 *   data-ribbon-offset-from-bottom (integer)
 *     - adjust ribbon upto the height by the given
 *       offset value from the bottom of the target element
 */
function adjustRibbonHeight() {
    var $ribbonTarget = $('.ribbon-target');

    if ($ribbonTarget.length) {
        var $navbar = $('nav.navbar-main');
        var $ad = $('.section-top-leaderboard');

        // get target's distance from top
        // not really scroll top
        // offsets for nav
        var targetTopDistance = $ribbonTarget.offset().top - $navbar.outerHeight() - $ad.outerHeight();
        var targetOffset = $ribbonTarget.data('ribbon-offset-from-bottom') == '1' ?
            $ribbonTarget.height() - 64 : $ribbonTarget.data('ribbon-offset');

        var offset = targetOffset || 64;
        // adjust ribbon height + 64px
        $('.page-ribbon, .header-ribbon').css('height', targetTopDistance + offset);
    }
}

function getCollapsesByGroup($boxCollapseGroup)
{
    var collapses = [];
    $boxCollapseGroup.find('.box-collapse').each(function(){
        var $el = $(this);
        var collapseId = $el.find('a').first().attr('href');
        var $collapse = $(collapseId).first();

        collapses.push($collapse);
    });

    return collapses;
}

/**
 * Reset action classes for box-collapse
 *
 * @param  $el
 */
function resetBoxCollapse($el) {
    $el.removeClass('box-collapsing-down')
        .removeClass('box-collapsed')
        .removeClass('box-collapsing-up');
}

/**
 * Scrolls to target id, with offset for the nav
 *
 * @param  $target
 */
function scrollToTarget($target) {
    var $body = $('body, html');
    var $nav = $('nav.navbar.navbar-main');

    if ($target.length) {
        var navHeight = $nav.innerHeight();
        var offsetTop = $target.offset().top;

        // get scrollTop of target
        // 32px for box-body padding
        $body.animate({ scrollTop: offsetTop - navHeight - 32 });
    }
}

/**
 * Checks if the target is visible in the screen
 * @param                       $target
 * @param  percentage decimal   $location [0-1] where
 *                              0 target is at bottom of the screen (not visible)
 *                              .5 target is in the middle of the screen
 *                              1 target is on top of the screen
 * @return {Boolean}
 */
function isTargetVisible($target, location) {
    // window jquery el
    var $window = $(window);

    // get the target's scroll top
    var triggerScrollTop = $target.offset().top;

    // current scroll top of the page
    var scrollTop = $window.scrollTop();

    // page's window height
    var windowHeight = $window.height();

    // get the location (from bottom to top) to (top to bottom)
    var percentageFromBottom = 1 - location; // 1 - .1 == .9 ~ 10% from bottom

    // get the target's croll top based on the location and window's height
    var targetScrollTop = triggerScrollTop - (windowHeight * percentageFromBottom);

    return scrollTop >= targetScrollTop;
}

/**
 * Checks if the current window scroll top is beyond the given scroll top value.
 * @param                       $target (in px)
 *
 * @return {Boolean}
 */
function isBeyondScrollTop(scrollTop) {
    // window jquery el
    var $window = $(window);

    // current scroll top of the page
    var windowScrollTop = $window.scrollTop();

    return scrollTop <= windowScrollTop;
}

/**
 * Determines if a needle string is in haystack array
 *
 * @param  string   string
 * @param  array    arr
 * @return bool
 */
function in_array(str, arr) {
    for (i = 0; i < arr.length; i++) {
        if (arr[i] == str) {
            return true;
        }
    }
    return false;
}

/**
 * Determines if browser is IE 8 or lower
 *
 * @return bool
 */
function isIe() {
    return $('html.ltIE9').length;
}

/**
 * Returns the outer height value of the top ad/leaderbord
 *
 * @return integer (px)
 */
function getTopAdHeight() {
    var $topAd = $('.section-top-leaderboard');
    var topAdHeight = 0;

    if ($topAd.length) {
        topAdHeight = $topAd.outerHeight();
    }

    return topAdHeight;
}

/**
 * Makes nav sticky based on leaderboard height
 *
 * @return bool
 */
function stickyNav() {
    var $body = $('body');
    var $nav = $('nav.navbar.navbar-main');
    var scrollTop = getTopAdHeight();
    var navOuterHeight = $nav.outerHeight();
    var isBeyondScrollTopValue = isBeyondScrollTop(scrollTop);

    // if $nav is on top of the screen
    if (isBeyondScrollTopValue) {
        $nav.addClass('navbar-fixed-top');
        // adjust body padding to replace navs spacing
        $body.css('padding-top', navOuterHeight);
    } else {
        $nav.removeClass('navbar-fixed-top');
        $body.css('padding-top', 0);
    }

    return isBeyondScrollTopValue;
}

function videoResize($el, ratio)
{
    var $iframe = $el.find('iframe').first();
    var width = $iframe.width();
    var height = getHeightByRatio(width, ratio);
    $iframe.css('height', height);
}

function getHeightByRatio(width, ratio)
{
    return Math.round(width * ratio);
}
