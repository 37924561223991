//////////////
// Floaters //
//////////////
/// requires underscore, jquery

(function($) {
    $.fn.cityUpdate = function(options) {
        // default option values
        options = (typeof options !== 'undefined') ? options : {};
        options.provinces = options.button || 'select[name="provinces[]"]';
        options.cities = options.content || 'select[name="cities[]"]';
        options.locations = options.locations || null;

        var CityUpdate = function ($form) {
            return {
                $el: null, // form
                $provinces: null,
                $cities: null,
                id: null,
                options: null,

                init: function () {
                    this.$el = $form;
                    this.options = options;
                    this.$provinces = this.$el.find(this.options.provinces).first();
                    this.$cities = this.$el.find(this.options.cities).first();
                    this.id = this.$el.attr('id') || null;

                    this.bind();

                    return this;
                },

                bind: function () {
                    var self = this;

                    // bind events
                    this.$provinces.on('change', function (e) { self.onProvincesChange(e, this); });
                    // this.$el.on('all', function (e) { self.onAll(e, this); });
                },

                // getters
                getProvinceValue: function () {
                    return this.$provinces.val();
                },

                getCitiesByProvince: function (value, attr) {
                    attr = (typeof attr !== 'undefined') ? attr : 'slug';
                    var province = _.find(this.options.locations, function(prov) {
                        return prov[attr] == value;
                    });

                    return province.cities;
                },

                // events
                onProvincesChange: function (e, target) {
                    this.loadCities();
                },

                // methods
                disableCityInput: function () {
                    this.$cities.prop("disabled", true);
                },

                enableCityInput: function () {
                    this.$cities.prop("disabled", false);
                },

                loadCities: function () {
                    var province = this.getProvinceValue();

                    if (province) {
                        var cities = this.getCitiesByProvince(province);

                        this.renderCityInput(cities);
                        this.enableCityInput();
                    } else {
                        this.resetCityInput();
                        this.disableCityInput();
                    }
                },

                renderCityInput: function (cities) {
                    var self = this;
                    this.resetCityInput();

                    _.each(cities, function (city) {
                        self.$cities.append(self.makeCityOption(city));
                    });
                },

                makeDefaultCityOption: function () {
                    return $('<option value="">- All Cities -</option>');
                },

                makeCityOption: function (city) {
                    var $cityOption = $('<option />');

                    $cityOption.html(city.name);
                    $cityOption.val(city.slug);

                    return $cityOption;
                },

                resetCityInput: function () {
                    this.$cities.html('').append(this.makeDefaultCityOption());
                },
            };
        };

        var App = function($forms) {
            return {
                forms: [],

                init: function () {
                    var self = this;
                    $forms.each(function (i, el) {
                        var $el = $(el);

                        self.forms.push( new CityUpdate($el).init() );
                    });

                    return this;
                },

                all: function () {
                    return this.forms;
                },

                get: function (id) {
                    var form = null;

                    $.each(this.all(), function (i, f) {
                        if (f.id == id) {
                            form = f;
                        }
                    });

                    return form;
                },
            };
        };

        return new App(this).init();
    };
})(jQuery);
