//////////////
// Floaters //
//////////////
/// requires jquery, underscore

(function($) {
    $.fn.floater = function(options) {
        // default option values
        options = (typeof options !== 'undefined') ? options : {};
        options.button = options.button || '.btn-close';
        options.content = options.content || '.floater-content';
        options.active = options.active || 'active';
        options.inputs = {};
        options.inputs.keyword = options.inputs.keyword || 'input[name="keywords[]"]';
        options.inputs.province = options.inputs.province || 'select[name="provinces[]"]';
        options.inputs.city = options.inputs.city || 'select[name="cities[]"]';
        options.filters = options.filters || '.search-filters-group';

        var Floater = function ($el) {
            return {
                $el: null,
                $btn: null,
                $content: null,
                id: null,
                options: null,
                $keywordInput: null,
                $provinceSelect: null,
                $citySelect: null,

                init: function () {
                    this.$el = $el;
                    this.options = options;
                    this.$btn = $el.find(this.options.button).first();
                    this.$content = $el.find(this.options.content).first();
                    this.$keywordInput = $el.find(this.options.inputs.keyword).first();
                    this.$provinceSelect = $el.find(this.options.inputs.province).first();
                    this.$citySelect = $el.find(this.options.inputs.city).first();
                    this.$filtersGroup = $el.find(this.options.filters).first();
                    this.id = this.$el.attr('id') || null;

                    this.bind();
                    this.checkShowFilters();

                    return this;
                },

                bind: function () {
                    var self = this;

                    // bind events
                    this.$btn.on('click', function (e) { self.onBtnCloseClick(e, this); return false; });
                    this.$keywordInput.on('keyup', function (e) { self.onKeywordInputKeyup(e, this); return false; });
                    this.$provinceSelect.on('change', function (e) { self.onProvinceSelectChange(e, this); return false; });
                    this.$citySelect.on('change', function (e) { self.onCitySelectChange(e, this); return false; });
                },

                // events
                onBtnCloseClick: function (e, target) {
                    e.preventDefault();
                    this.hide();
                },

                onKeywordInputKeyup: function (e, target) {
                    e.preventDefault();
                    this.checkShowFilters();
                },

                onProvinceSelectChange: function (e, target) {
                    e.preventDefault();
                    this.checkShowFilters();
                },

                onCitySelectChange: function (e, target) {
                    e.preventDefault();
                    this.checkShowFilters();
                },

                // methods
                isShown: function () {
                    return this.$el.hasClass(this.options.active);
                },

                show: function () {
                    this.$el.addClass(this.options.active);

                    // autofocus input
                    this.$el.find('*[autofocus]').focus();

                    this.$el.trigger('all');
                    this.$el.trigger('shown');
                },

                hide: function () {
                    this.$el.removeClass(this.options.active);

                    this.$el.trigger('all');
                    this.$el.trigger('hidden');
                },

                toggle: function () {
                    this.$el.toggleClass(this.options.active);//$content.slideToggle();

                    this.$el.trigger('all');
                },

                checkShowFilters: function () {
                    var keywordValue = this.$keywordInput.val();
                    var provinceValue = this.$provinceSelect.val();
                    var cityValue = this.$citySelect.val();

                    if (keywordValue || provinceValue || cityValue) {
                        // show filters
                        this.$filtersGroup.show();
                    } else {
                        // hide filters
                        this.$filtersGroup.hide();
                    }
                },
            };
        };

        var App = function($floaters) {
            return {
                floaters: [],

                init: function () {
                    var self = this;
                    $floaters.each(function (i, el) {
                        var $el = $(el);
                        self.floaters.push( new Floater($el).init() );
                    });

                    return this;
                },

                all: function () {
                    return this.floaters;
                },

                get: function (id) {
                    var floater = null;

                    $.each(this.all(), function (i, f) {
                        if (f.id == id) {
                            floater = f;
                        }
                    });

                    return floater;
                },
            };
        };

        return new App(this).init();
    };
})(jQuery);
